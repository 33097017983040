import algoliasearch, { SearchIndex } from 'algoliasearch';
import { ExternalDependencies, ProductIndex } from './types';

interface MyWindow extends Window {
  algoliasearch: ExternalDependencies.AlgoliaSearch;
}

export default class Algolia {
  private _client: ReturnType<typeof algoliasearch> | undefined;
  constructor(private _sdk?: ExternalDependencies.AlgoliaSdk) {}
  public prepare(applicationId: string, key: string): Algolia {
    if (!this._sdk && typeof window !== 'undefined') {
      const myWindow: MyWindow = window as MyWindow & typeof globalThis;
      this._sdk = myWindow.algoliasearch;
    }
    this._client = this._sdk(applicationId, key);
    return this;
  }

  public index(name: ProductIndex): SearchIndex {
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
    return this._client!.initIndex(name);
  }
}
