import { Response } from 'node-fetch';
import { Request, RequestInit, V1Configuration, VolusionV1Api } from './types';
import { ConfigVariable, PageText, StoreConfig } from './v1-types';

type RequestFn = (
  url: Request | string,
  init?: RequestInit
) => Promise<Response>;

export class LambdaApiV1 implements VolusionV1Api {
  private _baseUrl: string;

  constructor(private _transport: RequestFn, private _config: V1Configuration) {
    this._baseUrl = _config.apiUri;
  }

  public getStoreInformation(cookieHeader: string): Promise<StoreConfig> {
    return this._transport(`${this._baseUrl}/storeinformation`, {
      headers: {
        Cookie: cookieHeader,
      },
    })
      .then((res) => res.json())
      .catch(this.logAndThrow);
  }

  public getConfigVariables(): Promise<ConfigVariable[]> {
    const queryString = this.buildQueryString('name', configVariables);
    const uri = `${this._baseUrl}/configVariables?${queryString}`;

    return this._transport(uri)
      .then((res) => res.json())
      .catch((err) => {
        return this.logAndThrow(err, {
          uri,
        });
      });
  }

  public getPageText(): Promise<PageText[]> {
    const queryString = this.buildQueryString('id', pageTexts);
    const uri = `${this._baseUrl}/pageText?${queryString}`;

    return this._transport(uri)
      .then((res) => res.json())
      .catch((err) => {
        return this.logAndThrow(err, {
          uri,
        });
      });
  }

  private buildQueryString(key: string, values: unknown[]): string {
    return `${key}=${encodeURIComponent(values.join())}`
      .replace(/^&/, '')
      .replace(/&&/, '&');
  }

  private logAndThrow = (
    error: Error,
    context: Record<string, any> = {}
  ): never => {
    if (this._config.logger) {
      let msg = `SDK request failure: ${error.message}`;

      if (context) {
        const allContext = JSON.stringify({
          ...context,
          tenant: this._config.tenant,
        });
        msg = `${msg} (${allContext})`;
      }

      this._config.logger(msg);
    }
    throw new Error(error.message);
  };
}

const configVariables = [
  'Config_reCaptchaEnable',
  'Config_reCaptchaEmailBackInStock',
  'Config_EnableAnonymousReview',
  'Config_EnableCustomerReviews',
  'Config_Search_Auto_Complete',
  'Config_Prime_Checkout_Enabled_State',
  'Config_EnableDisplayAvailability',
  'Config_Enable_Parent_Categories_Display_Products_From_Subcategories',
  'Config_DisplayVendorCategory',
  'Config_reCaptchaCart',
  'Config_CustomField1_Title',
  'Config_EnableRecentHistory',
  'Config_reCaptchaSiteKey',
  'Config_EnableOptions_DisplayPopup',
  'Config_reCaptchaEmailPrice',
  'Config_VCompare_EnableSearchResults',
  'Config_reCaptchaBadge',
  'Config_EnableGiftWrap',
  'Config_CustomField2_Title',
  'Config_Enable_Free_Shipping_Qualify_Message',
  'Config_CategoryImageSize',
  'Config_SearchRefinement_EnableOnCategoryPage',
  'Config_EnableCoupons',
  'Config_reCaptchaRegister',
  'Config_reCaptchaTicketNew',
  'Config_reCaptchaCatalog',
  'Config_reCaptchaMailing',
  'Config_SearchRefinement_EnableSearchQtyOnCategoryPage',
  'Config_CustomField3_Title',
  'Config_SearchRefinement_HideFilter_ByPrice',
  'Config_reCaptchaLanguage',
  'Config_VCompare_EnableCategories',
  'Config_EnableRelatedProducts',
  'Config_reCaptchaReview',
  'Config_EnableLiveStockStatus',
  'Config_reCaptchaSendPass',
  'Config_EnableSEOFriendly_Hyphens',
  'Config_reCaptchaSize',
  'Config_EnableCategoryTree_DetailsPage',
  'Config_EnableImageZoom',
  'Config_reCaptchaType',
  'Config_reCaptchaLogin',
  'Config_reCaptchaCheckout',
  'Config_reCaptchaEmailFriend',
  'Config_reCaptchaSecretKey',
  'Config_EnableDisplayStockStatus',
  'Config_Enable_Search_Refinement',
  'Config_EnableAllowBackOrders',
  'Config_Enable_Back_In_Stock_Requests',
  'Config_CustomField4_Title',
  'Config_CategoryImageSize_Axis',
  'Config_CategoryImageSizeT',
  'Config_SearchRefinement_HideFilter_ByManufacturer',
  'Config_EnableAccessoriesBelowShoppingCart',
  'Config_EnableSEOFriendly',
  'Config_VCompare_MaxProducts',
  'Config_CategoryImageSizeT_Axis',
  'Config_reCaptchaTheme',
  'Config_CustomField5_Title',
  'Config_ShowAddCartAllCategories',
  'Config_EnableEmailAFriend',
  'Config_EnableBullets_On_Features_TechSpecs',
  'Config_EnableDisplayDiscountBtn',
  'Config_EnableMembersOnlyBrowsing',
  'Config_EnableMembersOnlyWebsite',
  'Config_EnableMembersOnlyWebsite_TaxID',
];

const pageTexts = [
  512, 513, 514, 515, 517, 518, 522, 523, 524, 525, 526, 531, 20, 532, 537, 548,
  549, 71, 617, 618, 620, 623, 636, 655, 656, 657, 659, 660, 664, 667, 669, 162,
  684, 685, 689, 690, 691, 698, 699, 203, 721, 210, 211, 215, 732, 745, 746,
  240, 244, 760, 819, 820, 827, 328, 329, 330, 331, 332, 334, 335, 336, 337,
  396, 420, 459, 460, 461, 462, 463, 464, 465, 466, 469, 470, 471, 479, 480,
  481, 482, 483, 484, 485, 489, 490, 496, 499, 500, 501, 502, 504, 505, 506,
  507, 509, 510, 511, 477,
];
